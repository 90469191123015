import { useState, useEffect } from "react";
import React from 'react';
import Modal from 'react-modal';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { id } from "date-fns/locale";
import { FormControlLabel, Switch, ToggleButton, Alert } from "@mui/material";

const SignInModal = ({ unused }) => {


  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [domSubModalIsOpen, setDomSubModalIsOpen] = React.useState(false);
  const [isDom, setIsDom] = React.useState(false);
  const [coupleId, setCoupleId] = React.useState(null);
  const [signInResult, setSignInResult] = React.useState(null);
  const coupleIdFromParam = new URLSearchParams(window.location.search).get('coupleCode');

  useEffect(() => {
    setCoupleId(coupleIdFromParam);
    if (coupleIdFromParam){
      setDomSubModalIsOpen(true);
    }
  }, [])
  var submitAuth = (isSignUp) => {
    const data = {
      username: username,
      password: password
    };
    if (isSignUp){
      data['is_dom'] = isDom;
      data['coupleId'] = coupleId;
    }

    var path = isSignUp ? '/api/auth/signup' : '/api/auth/login/password';

    fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(result => {
        // Handle the API response here
        setSignInResult(result);
        const delay = result.error ? 2000 : 0;
        if (!isSignUp || !result.error) {
          setTimeout(() => {
            window.location.reload();
          }, delay);
        }
      })
      .catch(error => {
        // Handle any errors here
        console.log(error)
        setSignInResult(error)
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  }


  /* ---- MODAL ---- */
  const renderDomSubModal = () => {
    const customStyles = {
      content: {
        top: '10%',
        left: '10%',
        right: '10%',
        bottom: '10%',
        //marginRight: '-1%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //transform: 'translate(-10%, -10%)',
      },
    };
    return (<div>

      <Modal
        isOpen={domSubModalIsOpen}
        onRequestClose={() => { setDomSubModalIsOpen(false)  }}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
      >
    <div className="alm-container">

    <main className="main-content">
      <div className="main-div">
        <span className="title">Sign Up</span>
        {signInAlert}
        <FormControl fullWidth >
        <TextField
          margin="normal"
          required
          id="signupUsername"
          label="Username"
          placeholder="kinkyanon"
          InputLabelProps={{
            shrink: true,
          }}
          value = {username}
          onChange={(event) => { setUsername(event.target.value) }}
        />
        <TextField
          margin="normal"
          required
          id="signupPassword"
          label="Password"
          placeholder=""
          InputLabelProps={{
            shrink: true,
          }}
          type="password"
          value = {password}
          onChange={(event) => { setPassword(event.target.value) }}
        />
        <TextField
          margin="normal"
          id="coupleId"
          label="Couple ID (Optional)"
          placeholder="123"
          InputLabelProps={{
            shrink: true,
          }}
          value={coupleId}
          onChange={(event) => { setCoupleId(event.target.value) }}
        />
        <FormControlLabel
          control={<Switch
            checked={isDom}
            onChange={(event) => { setIsDom(event.target.checked) }}
            name="checkedA"
          />}
          label={isDom ? "Account for Dom" : "Account for Sub"}
        />

        <Button
          onClick={() => { submitAuth(true) }}
          style={{
            alignSelf: "center",
          }}
          variant="contained">Sign Up</Button>
        </FormControl>
        </div>
      </main>
</div>
      </Modal>
    </div>)
  }
  /* ---- MODAL ---- */
  const signInAlert = signInResult ? (signInResult.error ? (
    <Alert variant="filled" severity="error">
      {signInResult.error}
    </Alert>
  ) : (
    <Alert variant="filled" severity="success">
      {signInResult.message}
    </Alert>
  )) : null;
  return (
    <div className="alm-container">

      <main className="main-content">
        <div className="main-div">
          <span className="title">Sign In</span>
          {signInAlert}
          <TextField
            margin="normal"
            required
            id="username"
            label="Username"
            placeholder=""
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => { setUsername(event.target.value) }}
          />
          <TextField
            margin="normal"
            required
            id="password"
            label="Password"
            placeholder=""
            InputLabelProps={{

              shrink: true,
            }}
            type="password"
            onChange={(event) => { setPassword(event.target.value) }}
          />
          <div style={{
            alignSelf: "center",
            display: "flex",
            margin: "30px"
          }}>
            <Button
              onClick={() => { submitAuth(false) }}
              style={{
                alignSelf: "center",
              }}
              variant="contained">Sign In</Button>
            <Button
              onClick={() => { setDomSubModalIsOpen(true) }}
              style={{
                alignSelf: "center",
              }}
              variant="contained">Sign Up</Button>
          </div>
          <span style={{marginTop:'10px', fontSize:'10px'}}>trackr is currently in beta.</span>
          <span style={{marginTop:'10px', fontSize:'10px'}}>let us know about any comments/thoughts at reddit.com/r/comfynetrackr</span>
          <span style={{marginTop:'10px', fontSize:'10px'}}>couples MUST be linked during sign-up at this time (by entering couple code for the second person to sign up), they can not be linked after account creation.</span>
        </div>
      </main>

      <footer className="footer">
      </footer>
      {renderDomSubModal()}
    </div>
  );

};
//['💦', '🔒', '😤' , '🔓', '📝', '🚫💦']
export default SignInModal;
/**
 * Header:
 * icon for switching to the previous month,
 * formatted date showing current month and year,
 * another icon for switching to next month
 * icons should also handle onClick events to change a month
 */
